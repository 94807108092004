<script setup lang="ts">
import FraudAndAmlScreeningWarning from './fraud-and-aml-screening-warning.vue'

const props = withDefaults(
  defineProps<{
    onClose?: () => void
    onCancel?: () => void
  }>(),
  {
    onClose: () => {},
    onCancel: () => {},
  }
)

const emit = defineEmits<{
  (event: 'hide'): void
}>()

function onClose() {
  emit('hide')
  props.onClose()
}

function onCancel() {
  emit('hide')
  props.onCancel()
}
</script>

<template>
  <fraud-and-aml-screening-warning @close="onClose" @cancel="onCancel" />
</template>
